import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import FeaturedSocial from "./FeaturedSocial"
import { BigRating } from "../../templates/index/RatingsAndReviews"


/**
 * As of 6-2-2020...
 * Google and Facebook queries are hard-coded for S DOS for their two locations (east and coastal).
 * Changing the Google My Business IDs in gatsby-node.js will break this component.
 * This component must either be commented out or adjusted per client.
 */
export default function ClientSocialReviews(props) {
  const data = useStaticQuery(graphql`
    query socialsQuery {
      coastalJson {
        data {
          rating
          totalReviews
          code
          platform
          platformId
          url
        }
      }
      eastJson {
        data {
          rating
          totalReviews
          code
          platform
          platformId
          url
        }
      }
      facebookJson {
        data {
          rating
          totalReviews
          code
          platform
          platformId
          url
        }
      }
    }
  `)

  let facebook = data.facebookJson.data;
  let google;
  let coastal = data.coastalJson
  let east = data.eastJson

  if (east.rating >= coastal.rating) {
    google = east.data;
  } else {
    google = coastal.data;
  }

  if (props.east) google = east;
  if (props.coastal) google = coastal;

  if (props.type === "big-rating") {

    // TO-DO: Un-hard code this
    let post = {
      homeRatingsReviews: {
        buttonOne: {
          buttonText: "More Reviews",
          href: "/patient-stories/"
        }
      }
    }


    return (
      <BigRating google={google} facebook={facebook} post={post} coastal={coastal} east={east} office={props.office}/>
    )
  }

  return (
    <div>
      <FeaturedSocial
        social={google}
        heading="Google"
        reviewNoun="Reviews"
      />
      {props.facebook && (
        <FeaturedSocial
          social={facebook}
          heading="Facebook"
          reviewNoun="Recommendations"
        />
      )}
    </div>
  )

}